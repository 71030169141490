/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import parse from 'url-parse';
import UAParser from 'ua-parser-js';
import 'sanitize.css';
import 'prism-themes/themes/prism-ghcolors.css';
// prism-a11y-dark.css o
// prism-duotone-light.css o
// prism-base16-ateliersulphurpool.light o
// prism-darcula.css o
// prism-pojoaque.css o
// prism-ghcolors.css △
// prism-duotone-dark.css x
// prism-atom-dark.css x
// prism-cb.css x
// prism-duotone-earth.css x
// prism-duotone-forest.css x
// prism-duotone-sea.css x
// prism-duotone-space.css x
// prism-hopscotch.css x
// prism-vs.css x
// prism-xonokai.css x
import supportedBrowsersRegExp from '@src/utils/dist/supportedBrowsersRegExp';
import supportedBrowsersList from '@src/utils/dist/supportedBrowsersList';

if (process.env.NODE_ENV === 'production'
    && !supportedBrowsersRegExp.test(navigator.userAgent)
    && window.location.pathname.indexOf('/not-supported') !== 0) {
  const url = parse(window.location.href, true);
  const redirectPath = (url.query && url.query.redirectPath)
        ? url.query.redirectPath
        : window.location.pathname;

  const uaParser = new UAParser();
  const browserName = uaParser.getBrowser();
  // IEのみ崩れているのでとばす
  // TODO not-supported pageの内容をポップアップにし、見れなくするのではなく警告のみにする
  console.log(browserName);
  if (browserName.name === 'IE') {
    window.location = `/not-supported?redirectPath=${redirectPath}`;
  }
}
